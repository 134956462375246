<template>
	<div class="mt-3">
		<b-form @submit.prevent="checkBag">

            <div class="row mb-3">
                <div class="col-sm-5 m-auto">
					<b-form-group
                    id="deletelmawb"
                    label="Enter LM-AWB line by line"
                    label-for="deletelmawb"
                    :description="description">
                    <div class="input-group-addon">
						<b-form-textarea
							id="deletel-mawb"
							type="text"
							ref="lmawbs"
							v-model="lmawbs"
							placeholder="9400136XXXXXXXXXXX0481
9400136XXXXXXXXXXX0482
9400136XXXXXXXXXXX0483"
							rows="5"
							max-rows="10"
							required
						></b-form-textarea>
						</div>
					</b-form-group>
					<b-button variant="warning" :disabled="line > maxlm" @click="deletelmawb">Delete LM-AWBs</b-button>
					<div v-if="failed_awb.length > 0" class="mt-4">
						<h6>Failed to delete</h6>
						<ul class="list-inline">
							<li v-for="awb in failed_awb" :key="awb">
								{{awb}}
							</li>
						</ul>
					</div>
                </div>
            </div>

		</b-form>
	</div>
</template>

<script>
export default {
	name: "DeleteLmAWB",
	title: "Delete LM-AWB",
	data() {
		return {
			lmawbs: '',
			description: '',
			maxlm: 10,
			line: 0,
			failed_awb: []
		}
	},
	methods: {
		async deletelmawb() {
			if(confirm(`Are you sure? Want to delete LM-AWBs.`)) {
				try {

					let lmarray = this.lmawbs.split('\n')
					const res = await this.axios.post(`/delete-lmAwb`, {lm_awb: lmarray});

					if(res.data.success) {
						this.popToast('Deleted!', res.data.message, 'success')
					} else {
						this.failed_awb = res.data.FailedAwbs
						this.popToast('Failed!', res.data.message, 'danger')
					}

					this.$refs.lmawbs.focus()
					this.lmawbs = ''
					this.description = ''
				} catch (err) {
					console.error(err)
				}
			}
		}
	},
	watch: {
		lmawbs: function(newVal) {
			if(newVal.length > 0) {
				this.line = newVal.split('\n').length
				this.description = `${this.line} / ${this.maxlm} LM-AWB`
			}
		}
	}
}
</script>